import React from "react"
import styled from "styled-components"
import { COLUMNS_LOCATION } from "../../constants"
import Image from "gatsby-image"

const BackgroundContainer = styled.div`
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: ${({ height }) => height || "auto"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Columns = styled.div`
  width: 2px;
  background: rgba(50, 61, 72, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ left }) => left};
`

const Background = ({
  children,
  bgUrl,
  height,
  withColumns = false,
  fluid,
}) => {
  return (
    <>
      <BackgroundContainer height={height} bgUrl={bgUrl}>
        {fluid && (
          <Image
            fluid={fluid}
            loading="auto"
            fadeIn
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              bottom: 0,
              top: 0,
            }}
          />
        )}
        {withColumns &&
          COLUMNS_LOCATION.map(column => (
            <Columns key={column} left={`${column}%`} />
          ))}
        {children}
      </BackgroundContainer>
    </>
  )
}

export default Background
