import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import Seo from "../components/seo"
import styled from "styled-components"
import { FlexContainer } from "../styles/layout.styled"
import Form from "../components/Contact/Form"
import Background from "../components/reusable/Background"
import { useInView } from "react-intersection-observer"
import ContactInfo from "../components/Contact/ContactInfo"
import { graphql, useStaticQuery } from "gatsby"

const ContactPageContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 62px 30px 155px;
  background: ${({ theme }) => theme.white};
`

const ContactPageContent = styled(FlexContainer)`
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  max-width: 1440px;
  justify-content: space-evenly;
`
const PageTitle = styled.h1`
  color: ${({ theme: { white } }) => white};
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 0;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0) scale(1)" : "translateX(500px) scale(0)"};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(250px) scale(0)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(100px) scale(0)"};
  }
`

const ContactPage = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const { contact } = useStaticQuery(graphql`
    {
      contact: contentfulWebsiteImages(title: { eq: "contact" }) {
        image {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
    }
  `)

  return (
    <LayoutRoot>
      <Seo title="Contact-Page" />
      <Background fluid={contact.image.fluid} height="249px">
        <FlexContainer margin="71px 0 0">
          <PageTitle ref={titleRef} inView={titleInView}>
            GET IN TOUCH
          </PageTitle>
        </FlexContainer>
      </Background>
      <ContactPageContainer>
        <ContactPageContent>
          <Form />
          <ContactInfo />
        </ContactPageContent>
      </ContactPageContainer>
    </LayoutRoot>
  )
}

export default ContactPage
